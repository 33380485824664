<template>
  <NuxtLayout name="default">
    <Breadcrumb :items="breadCrumbs" class="mt-5 md:mt-0 text-primary-700 gap-x-2 container mx-auto" />
    <MainTitle class="mt-8">
      <template #before>
        {{ $t('title') }}
      </template>
    </MainTitle>
    <p class="container mx-auto text-center text-secondary-700 mt-7">
      {{ $t('description') }}
    </p>

    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
useAppSeoMeta(
  computed(() => ({
    title: $t('title'),
    description: $t('description'),
    type: 'website',
    keywords: 'register, product',
  })),
);

const breadCrumbs = [
  { label: $t('home'), path: '/home' },
  {
    label: $t('registerProduct'),
    path: '/register-product',
  },
];
</script>

<i18n>
{
    "en": {
        "title": "Register Product",
        "description": "Register your product.",
        "home": "Home",
        "registerProduct": "Register Product",
        "support": "Support"
    },
    "ar" : {
        "title": "سجل المنتج",
        "description": "سجل منتجك.",
        "home": "الرئيسية",
        "registerProduct": "سجل المنتج",
        "support": "الدعم"
    }
}
</i18n>
